<template>
  <section class="blank-page">
    <pageLoader v-if="isload" :loadingText="'Traitement des données en cours'"/>
    <div class="page-header">
      <h3 class="page-title">Ajout d'un produit</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">e-Commerce</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Ajout d'un produit
          </li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- snotify -->
          <!-- <button @click="showSnotify()">snotify</button>
                <simpleSnotifysuccess /> -->

          <div class="card-body">
            <!-- ajout catalogue -->
            <div class="row">
              <div class="col-md-6">
                <form class="forms-sample" @submit.prevent="handleSubmit">
                  <!-- categories  select -->
                  <div>
                    <b-form-group
                      label="Catégories"
                      label-for="tags-component-select"
                    >
                      <b-form-select
                        v-model="category"
                        :options="categories"
                        value-field="ulid"
                        text-field="category"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <!-- name -->
                  <b-form-group
                    id="name"
                    label="Nom du produit"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="name"
                      :state="$v.name.$dirty ? !$v.name.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <!-- marque -->
                  <b-form-group
                    id="name"
                    label="Marque du produit"
                    label-for="mark"
                  >
                  <input class="form-control" list="marklist" v-model="mark" id="mark" name="mark" />
                  <datalist id="marklist">
                    <option value="Nohem/ Beauty Garden">Nohem/ Beauty Garden</option>
                    <option value="1944 Paris">1944 Paris</option>
                    <option value="Kure bazaar">Kure bazaar</option>
                  </datalist>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- price -->
                  <b-form-group
                    id="price"
                    label="Prix du produit (€) (htc)"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      type="number"
                      step="0.01"
                      name="example-input-1"
                      v-model="price"
                      :state="$v.price.$dirty ? !$v.price.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <label for="">Prix ttc (20%): {{roundNumber(price*1.2)}}€</label>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire et doit contenir un nombre.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- tags -->
                  <b-form-group
                    id="tags"
                    label="TAGS"
                    label-for="example-input-1"
                  >
                    <b-form-tags
                      id="example-input-1"
                      name="example-input-1"
                      v-model="tags"
                      :state="$v.tags.$dirty ? !$v.tags.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-tags>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire et doit contenir un nombre.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <!-- color -->
                  <b-form-group
                    label="Couleur"
                  >
                    <b-input-group
                      id="color"
                      label-for="example-input-1"
                    >
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="color"
                        :state="$v.color.$dirty ? !$v.color.$error : null"
                        aria-describedby="input-1-live-feedback"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        Ce champ est obligatoire et doit contenir un nombre.
                      </b-form-invalid-feedback>
                      <b-input-group-text slot="append">
                        <!-- <button class="btn btn-sm btn-primary btn-rounded">?</button> -->
                        <span class="badge badge-outline-primary badge-pill my-0 mx-2">?</span>
                      </b-input-group-text>
                    </b-input-group>
                  </b-form-group>

                  <!-- stock -->
                  <b-form-group
                    id="stock"
                    label="Stock du produit"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      type='number'
                      v-model="stock"
                      :state="$v.stock.$dirty ? !$v.stock.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire et doit contenir un nombre.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- volume -->
                  <b-form-group
                    id="volume"
                    label="Volume du produit (ml)"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      v-model="volume"
                      type="number"
                      step="0.01"
                      :state="$v.volume.$dirty ? !$v.volume.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire et doit contenir un nombre.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- weight -->
                  <b-form-group
                    id="weight"
                    label="Poids du produit (g)"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      type="number"
                      v-model="weight"
                      :state="$v.weight.$dirty ? !$v.weight.$error : null"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire et doit contenir un nombre.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- shippinglocation -->
                  <b-form-group
                    id="shippinglocation"
                    label="Lieu d'expédition"
                    label-for="example-input-1"
                  >
                    <b-form-input
                      id="example-input-1"
                      name="example-input-1"
                      list="country"
                      v-model="shippinglocation"
                      :state="
                        $v.shippinglocation.$dirty
                          ? !$v.shippinglocation.$error
                          : null
                      "
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <datalist id="country">
                      <option value="any">Partout</option>
                      <option v-for="(country, countryId) in countries" :key="countryId" :value="country.name_fr">{{country.name_fr}}</option>
                    </datalist>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- description -->
                  <b-form-group
                    id="description"
                    label="Description"
                    label-for="example-input-1"
                  >
                    <b-form-textarea
                      id="example-input-1"
                      name="example-input-1"
                      v-model="description"
                      :rows="3"
                      :max-rows="6"
                      :state="
                        $v.description.$dirty ? !$v.description.$error : null
                      "
                      aria-describedby="input-1-live-feedback"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      Ce champ est obligatoire.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <div class="d-flex">
                    
                    <b-button type="submit" variant="success" class="mr-2"
                      >Valider</b-button
                    >
                    <b-button
                      variant="light"
                      class="mr-2"
                      @click="$router.go(-1) && showSnotify()"
                      >Annuler</b-button
                    >
                  </div>
                </form>
                <vue-snotify></vue-snotify>
              </div>
            </div>
            <!-- Modal Ends -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require("axios").default;
import { validationMixin } from "vuelidate";
import { required, minLength,decimal,numeric,maxLength } from "vuelidate/lib/validators";
import pageLoader from '../../components/pageLoader.vue'
import {priceMixin} from '../mixins/price.js'

export default {
  components: {
    pageLoader
  },
  name: "addCatalogue",
  mixins: [validationMixin, priceMixin],
  data() {
    return {
      ulid: null,
      isload : false,
      category: "",
      name: "",
      price: "",
      stock: "",
      tags:"",
      volume: "",
      weight: "",
      shippinglocation: "partout",
      description: "",
      mark:"",
      color:"standard",
      // value:'',
      categories: [],
      countries:[],
      // test: "",
      // options: ["tag1", "tag2"],
      // array: [
      //   { name: "object1", id: "1" },
      //   { name: "object2", id: "2" },
      // ],
      // array2: [
      //   { text: "object1", value: "test1" },
      //   { text: "object2", value: "test2" },
      // ],
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
    },
    price: {
      required,
      decimal,
    },
    stock: {
      required,
      numeric
    },
    tags:{
      required,
    },
    volume: {
      required,
      decimal
    },
    weight: {
      required,
      decimal
    },
    shippinglocation: {
      required,
      maxLength: maxLength(8),

    },
    description: {
      required
    },
    color:{
      required
    }
  },
  computed: {
    // availableOptions() {
    //   let availableArray = this.categories.filter(
    //     (opt) => this.selectedCategory.indexOf(opt) === -1
    //   );
    //   return availableArray;
    //   // for(let x=0; x < availableArray.length; x++) {
    //   //   return x.name
    //   // }
    //   // return
    // },
    // selectedCategoryId() {
    //   let filteredarray = this.categories.filter((ar) =>
    //     this.selectedCategory.includes(ar["category"])
    //   );
    //   let categories = [];
    //   // return filteredarray
    //   for (let x = 0; x < filteredarray.length; x++) {
    //     categories.push(filteredarray[x]["ulid"]);
    //   }
    //   return categories;
    // },
  },
  methods: {
    // add products
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      // si POST (ulid vide)
      if (!this.ulid) {
        if (this.shippinglocation == "partout" || this.shippinglocation == "Partout") {
          this.shippinglocation = "any"
          alert(this.shippinglocation)
        }
        let product = {
          category: this.category,
          name: this.name,
          mark: this.mark,
          tags:this.tags,
          price: Number(this.price),
          stock: Number(this.stock),
          volume: Number(this.volume),
          weight: Number(this.weight)*0.001,
          shippinglocation: this.shippinglocation,
          description: this.description,
          color:this.color,
          isactive:true
        };
        this.isload =true
        axios
          .post("products", product, {
            headers: {
              "X-AUTH-TOKEN": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.$snotify.info("Ajouté avec succès !!");
            res
            this.$router.push({ name: "catalogue" });
          })
          .catch((err) => console.log("response err product", err));
          this.isload =false

      }
      // PUT
      else {
         if (this.shippinglocation == "partout") {
          this.shippinglocation == "any"
        }
        let product = {
          ulid: this.ulid,
          category: this.category,
          name: this.name,
          mark: this.mark,
          tags:[this.tags],
          price: Number(this.price),
          stock: Number(this.stock),
          volume: Number(this.volume),
          weight: Number(this.weight)*0.001,
          shippinglocation: this.shippinglocation,
          description: this.description,
          color:this.color
        };
        console.log("si modifier");
        this.isload =true
        axios
          .put(`products/${this.ulid}`, product, {
            headers: {
              "X-AUTH-TOKEN": localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("PUT", res)
            this.$router.push({ name: "catalogue"});
            })
          .catch((err) => console.log("errorPUT", err));
      }
        this.isload =true
    },
    // getProduct to edit params via catalogue
    getProduct() {
      if (this.ulid) {
        // alert ('put')
        this.isload = true
        axios
          .get(`products/${this.ulid}`)
          .then((res) => {
              (this.putProduct = true);
              console.log(res.data);
            //insert result in data
            this.name = res.data.name;
            this.mark = res.data.mark;
            this.price = res.data.price;
            this.stock = res.data.stock;
            this.tags =  res.data.tags;
            this.volume = res.data.volume;
            this.weight = Number(res.data.weight)/0.001;
            this.shippinglocation = res.data.shippinglocation;
            this.description = res.data.description;
            this.category = res.data.category.ulid
            this.color = res.data.color
            // let categories = this.categories.filter((ar) =>
            //   res.data.ulidcategory.includes(ar["ulid"])
            // );
            // for (let x = 0; x < categories.length; x++) {
            //   this.selectedCategory.push(categories[x]["category"]);
            // }
            // this.selectedCategory=selectedCategory
          })
          .catch((err) => console.log(err));
      } else {
        // alert('post')
      }
      this.isload = false
    },
    getCountries(){
      {
      axios.get('countries')
      .then(resGetCountries => {
        this.countries = resGetCountries.data.result
      })
      .catch(errGetCountries => console.log(errGetCountries))
    }
    },
    
  },
  mounted() {
    //récupération des pays pour les lieux d'expéditions
    this.getCountries()
    // call edit product params
    this.productdetails = this.$route.params.product;
    this.ulid = this.$route.params.ulid;
    // console.log ('détail product', this.productdetails)
    // format date
    (this.datepublished = new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "")),
      (this.datemodified = new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "")),
      //charge product via methods
      this.getProduct(),
      //get categories to form
        this.isload = true
      axios
        .get("categories")
        .then((res) => {
          this.categories = res.data.result;
        this.isload = false

        })
        .catch((err) => console.log("err cat", err));

    //get collection to form
    axios
      .get("collections")
      .then((res) => {
        this.collections = res.data.result;
      })
      .catch((err) => console.log("err cat", err));
  },
};
</script>
